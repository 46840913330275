.skills-page {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    .text-container {
        max-width: 50rem;
        text-align: left;
        margin: 5rem;

        .skills-title {
            font-size: 5rem;
            margin-left: 2rem;
            margin-top: -2rem;
            margin-bottom: 0;

            &::before{
                content: '<h1>';
                display: block;
                text-align: left;
                font-size: 2rem;
                margin: -2rem;
                opacity: 0.6;
            }
            &::after {
                content: '</h1>';
                display: block;
                text-align: left;
                font-size: 2rem;
                margin: -2rem -2rem 0 -2rem;
                opacity: 0.6;
            }
        }

        .skill-section {
            display: flex;
        }

        .skill-area {
            font-size: 2rem;
            margin-left: 1rem;
            opacity: 1;
        }

        .skill-desc {
            margin-top: -1.5rem;
            margin-left: 6rem;
            font-size: 1.4rem;
            font-weight: 500;
        }

    }

    .cube-outer {
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;;
    }

    .cube-container {
        width: 30rem;
        height: 30rem;
        padding-left: 10rem;
        padding-top: 10rem;
        overflow: hidden;
        align-items: center;
        .cubespinner {
            animation-name: spincube;
            animation-timing-function: ease-in-out;
            animation-iteration-count: infinite;
            animation-duration: 12s;
            transform-style: preserve-3d;
            transform-origin: 10rem 10rem 0;
    
            div {
                position:absolute;
                width: 20rem;
                height: 20rem;
                border: .05rem solid #CCC;
                background: rgba(191,184,183,0.3);
                font-size: 10rem;
                display: flex;
                justify-content: center;
                align-items: center;
                box-shadow: 0 0 1.4rem 1.4rem #91a388;
                border-radius: 1rem;
            }
            .face1 {
                transform: translateZ(10rem);
                
            }
            .face2 {
                transform: rotateY(90deg) translateZ(10rem);
                
            }
            .face3 {
                transform: rotateY(90deg) rotateX(90deg) translateZ(10rem);
                
            }
            .face4 {
                transform: rotateY(180deg) rotateZ(90deg) translateZ(10rem);
                
            }
            .face5 {
                transform: rotateY(-90deg) rotateZ(-90deg) translateZ(10rem);
                
            }
            .face6 {
                transform: rotateX(-90deg) translateZ(10rem);
                
            }
        }
    }
}

@keyframes spincube {
    from,
    to {
        transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
    }
    16% {
        transform: rotateY(-90deg);
    }
    33% {
        transform: rotateY(-90deg) rotateZ(90deg);
    }
    50% {
        transform: rotateY(-180deg) rotateZ(90deg);
    }
    66% {
        transform: rotateY(-270deg) rotateZ(90deg);
    }
    83% {
        transform: rotateX(90deg);
    }
}

@media only screen and (max-width: 1200px) {
    .skills-page .text-container .skill-desc {
        margin-left: 2rem;
        margin-top: 0;
    }
}

//phones
@media only screen and (max-width: 871px) {
    .skills-page {
        max-width: 100%;        
        flex-direction: column;
        margin: 0;
        padding: 0;
        box-sizing: border-box;

        .text-container {
            margin: 2rem;
            padding: 2rem;
            height: auto;            
          }


        .cube-outer {
            width: 100%;
            height: auto;
        }
      }     
}

@media only screen and (max-width: 523px) {

    .skills-page .cube-container {
        overflow: hidden;
        padding: 5rem;
    }

}