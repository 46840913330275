$dark-brown: #3d2f2a;
$primary-color: #3d2f2a; //go get the loader to the right color
$primary-brown: #837265;
$light-brown: #bfb8b7;
$light-green: #91a388;
$green: #597462;

@import 'animate.css';
@import 'loaders.css/src/animations/ball-clip-rotate-pulse.scss';

@font-face {
  font-family: 'Urbanist', sans-serif;
  src: url('https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&display=swap');
}

input, textarea, select {
  font-family: 'Urbanist', sans-serif;
}

* {
  margin: 0;
  padding: 0;
}

html {
  font-family: 'Urbanist', sans-serif;
  font-size: 90%;
}

body {
  margin: 0;
  font-family: 'Urbanist', sans-serif;
  color: $dark-brown;
  background: $primary-brown;
  line-height: 1.6;
  min-height: 100vh;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  width:100%;
  display: block;
  overflow: auto;
}

@media screen and (max-width: 1200px) {
  body {
    overflow: visible;
  }
}



