.contact-page {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    .title {
        font-size: 3rem;
    }

    .contact-desc {
        font-size: 1.3rem;
    }

    .contact-form {
        width: 100%;
        margin-top: 1.4rem;

        ul {
            padding: 0;
            margin: 0;
            li {
                padding: 0;
                list-style: none;
                margin-bottom: 0.625rem;
                display: block;
                position: relative;
            }
             .half{
                display: inline-block;
                width: 49%;
                margin-left: 2%;
                clear: none;

                &:first-child {
                    margin-left: 0;
                }
            }
        }
        input {
            border-radius: .5rem;
        }

        input[type='text'],
        input[type='email'] {
            width: 100%;
            height: 5rem;
            border: 0;
            background: #bfb8b7;
            font-size: 1.8rem;
            font-weight: 500;
            color: black;
            padding: 1.4rem;
            box-sizing: border-box;
        }
        textarea {
            width: 100%;
            border: 0;
            background: #bfb8b7;
            height: 15rem;
            font-size: 1.8rem;
            font-weight: 500;
            color: black;
            padding: 1.4rem;
            min-height: 10rem;
            box-sizing: border-box;
            border-radius: .5rem;
        }
        .flat-button {
            width: 6.25rem;
            height: 2.8rem;
            color: #3d2f2a;
            background: 0 0;
            font: 1.4rem;
            font-weight: 700;
            letter-spacing: .25rem;
            text-decoration: none;
            padding: 0.5rem 0.625rem;
            border: .2rem solid #3d2f2a;
            float: right;
            border-radius: .5rem;
        }
        .flat-button:hover{
            opacity: 0.4    ;
        }
    }

    .links-container {
        padding: 3.4rem;

        a {
            color: #3d2f2a;
            text-decoration: none;
        }
        a:hover {
            opacity: 0.5;
        }

        .external-link {
            margin: 1rem;
        }
    }
}

@media only screen and (max-width: 650px) {
    .contact-page {
        width: 100%;
        height: auto;

        .contact-desc {
            max-width: 90%;
            margin: 2rem;
        }

        .text-content {
            .contact-form {
                max-width: 90%;
                margin: 1rem;
            }
        }
    }
    .contact-page .contact-form .flat-button {
        float: none;
    }
}