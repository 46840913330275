header {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    align-items: center;
    justify-content: space-between;
    height: 5rem;
    padding: 0 2rem;
    background-color: rgba($color: #837265, $alpha: 0.6);
    -webkit-backdrop-filter: blur(8px);
    backdrop-filter: blur(8px);  
    z-index: 99; 
}

.navLogo {
    width: 12rem;
    height: auto;
    padding-left: 0;
    margin-top: 12px;
}

.navList {
    list-style: none;
    display: flex;
    align-items: center;
    margin-left: -4rem;
}

.navLink {
    margin: 0 1rem;
    display: inline-block;
    text-align: center;
    font-size: 2rem;
    font-weight: bold;;
    margin: 0 1rem;
    padding: 1rem;
    cursor: pointer;
}

.navListItem:hover {
    opacity: 0.5;
}

header .nav-btn {
    padding: .5rem;
    cursor: pointer;
    background: none;
    border: none;
    outline: none;
    visibility: hidden;;
    opacity: 0;
    font-size: 1.8rem;
}

@media only screen and (max-width: 1024px) {
    header {
        height: 3.5rem;
    }

    .navLogo {
        width: 8rem;
        position: absolute;
        top: 0;
        left: 0;
        padding: .5rem;
        padding-top: .3rem;
    }
	header .nav-btn {
        position: absolute;
        top: 0;
        right: 0;
		visibility: visible;
		opacity: 1;
        color: #3d2f2a;
        padding: .8rem;
	}

	header nav {
		position: fixed;
		top: 0;
		left: 0;
        right: 0;
		width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
		gap: 1.5rem;
		background-color: #3d2f2a;
		transition: 1s;
		transform: translateY(-100vh);
        z-index: 1 !important;
	}
    #nav {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

	header .responsive_nav {
		transform: none;
	}

	nav .nav-close-btn {
		position: absolute;
		top: 2rem;
		right: 2rem;
        color: #bfb8b7;
	}

	nav a {
		font-size: 1.4rem;
        color: #bfb8b7;
	}

}