.about-page {
    min-height: 100vh;
    display: block;
    text-align: center;

    .intro-par{
        padding: 2rem;
    }
    
    .title{
        font-weight: 600;
        font-size: 1.4rem;
    }
    .title-msg {
        font-weight: 800;
        font-size: 2.8rem;
    }

    .about-info {
        margin: auto;
        display:flex;
        width: 70%;
        margin-top: 1.8rem;
        justify-content: center;
        align-items: center;
        
        .headshot{
            width: 18rem;
            border-radius: 100em;
            box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
        }
        .about-me {
            text-indent: 1.2rem;
            display: block;;
            font-size: 1.4rem;
            font-weight: 500;
            text-align: left;
            margin-left: 1.8rem;
        }
    }

    .project-header{
        font-weight: 600;
        font-size: 1.4rem;
    }
    .project-msg {
        font-weight: 800;
        font-size: 2.8rem;
        margin: 0;
        padding: 0;
    }
    .project-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.cards-array{
    display: flex;
}

@media only screen and (max-width: 1200px) {
    .about-page .about-info {
        margin: 0;
        padding: 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .cards-array {
        display: block;
    }
}

//phones
@media only screen and (max-width: 768px) {
    .about-page .about-info { 
        flex-direction: column;

        .headshot {
            width: 14rem;
            margin-bottom: .8rem;
        }
    }
}