.card {
    background: #3d2f2a;
    padding: 2rem 4rem;
    color: #bfb8b7;
    cursor: pointer;
    min-height: 21rem;
    height: auto;
    width: auto;
    margin: 0 10px 10px 0;
}
.card p {
    padding-top: 1rem;
    line-height: 150%;
}
.expanded {
    max-width: 28rem;
     
    #mobileCardSuggest {
        visibility: hidden;
    }
}
.project-description {
    white-space: normal;
    font-size: 1.2rem;
    margin-bottom: 1rem;
}
.project-image {
    height: 15rem;
    width: auto;
    max-width: 15rem;
    border-radius: .5rem;
    margin-bottom: 1.3rem;
}
.project-link {
    text-decoration: none;
    color: #bfb8b7;
    font-size: 1rem;
}
.project-link:hover {
    text-decoration: underline;
}
.project-link-icon {
    margin-left: .1rem;
    margin-top: 0;
}

#mobilCardSuggest {
    visibility:hidden
}


//phones
@media only screen and (max-width: 400px) {
    .card {
        max-width: 90%;
        margin-left: 3rem;
        margin-right: 3rem;
    }
}
