.home-page {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    .logo-container {
        .main-logo {
            width: 100%;
            animation: fadeInAnimation ease 4s;
            animation-iteration-count: 1;
            animation-fill-mode: forwards;
        }
        .logo-caption {
            font-size: 2rem;
            font-weight: 700;
        }
    }
}

@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@media only screen and (max-width: 1024px) {
    .home-page .logo-container .logo-caption {
        font-size: 1.2rem;
    }
}